<template>
  <section class="content">
    <div class="flex justify-content-center">
      <div class="w-100 card">
        <div class="card-body">
          <div class="row">
            <div class="col-9">
                <div class="row form-horizontal">
                <div class="col-auto">
                    <label class="control-label my-2 ml-2">
                        <h6>Filter Pencarian</h6>
                    </label>
                </div>
                <div class="col-auto">
                    <div class="input-group mb-3">
                        <input type="text" ref="daterange" class="form-control" style="border-right: 0" />
                        <div class="input-group-append">
                            <div class="input-group-text" style="background-color: #fff">
                                <span><span class="fa fa-calendar"></span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto">
                    <select class="form-control" @change="statusType($event)">
                        <option value="" disabled selected>Status Approval</option>
                        <option value="1">Approve</option>
                        <option value="2">Request</option>
                        <option value="3">Reject</option>
                        <option value="0">Semua</option>
                    </select>
                </div>
              </div>
            </div>
          </div>
          <div class="table-ticket">
            <table class="table table-hover" ref="tblverif" id="tblverif">
              <thead>
                <tr>
                  <th>ID SHIPPER</th>
                  <th>NIK</th>
                  <th>NAMA LENGKAP</th>
                  <th>NO. TELEPON</th>
                  <th>EMAIL</th>
                  <th>TANGGAL PENGAJUAN</th>
                  <th>STATUS APPROVAL</th>
                  <th>TINDAKAN</th>
                </tr>
              </thead>
              <tbody @click="handleClick"></tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>
<script>
import { createTable, authFetch, auth } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import 'jquery-ui';
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import moment from "moment";

export default {
  name: "Ticket",
  data() {
    return {
        dateStart: moment().startOf("month").format("YYYY-MM-DD"),
        dateEnd: moment().format("YYYY-MM-DD"),
        method: "",
        roles: "",
        allTicket: {},
        downloading: false,
        show: false,
        filter: {},
        form: {
        status: "",
        },
        dt1: moment().startOf("month"),
        dt2: moment(),
        data_type: "createdOn",
        status_approval: 0,
    };
  },
  watch:{
    $route (to, from){
    //   if(to.path === "/settings/tickets/all")
    //   {
    //     // sessionStorage.filterData = '';
	// 	    // sessionStorage.filterStatus = '';
    //     // this.filter = {};
    //     sessionStorage.clear();
    //     this.table.api().ajax.reload();
    //     this.loadAllStatus();
    //   }
    }
  },
  created: function () {
    // console.log("filter: ",this.$route.params);
    this.roles = this.$route.meta.roles;
    this.userInfo = auth.user();
    if(this.$route.params.filter) {
      this.filter = this.$route.params.filter;
    //   this.loadAllStatus(this.filter);
    } else {
        this.filter.dt1 = this.dt1.format('YYYY-MM-DD');
        this.filter.dt2 = this.dt2.format('YYYY-MM-DD');
        // this.loadAllStatus(this.filter);
    }

    if(this.filter.dt1 && this.filter.dt2)
    {
      this.dt1 = moment(this.filter.dt1);
      this.dt2 = moment(this.filter.dt2);
    } else {
        this.filter.dt1 = this.dt1.format('YYYY-MM-DD');
        this.filter.dt2 = this.dt2.format('YYYY-MM-DD');
    }
    this.dateStart = this.dt1.format('YYYY-MM-DD');
    this.dateEnd = this.dt2.format('YYYY-MM-DD');
    // if(this.filter.gid)
    // {
    //   this.groupId = this.filter.gid;
    //   this.dateStart = '';
    //   this.dateEnd = '';
	//   }
    if(Object.keys(this.filter).length<1)
    {
      if(sessionStorage.filterData)this.filter = JSON.parse(sessionStorage.filterData);
    }
    else{
      sessionStorage.filterData = JSON.stringify(this.filter);
      sessionStorage.filterStatus = 1;
    }
    // this.filter.datatype = this.data_type;

    // if (this.filter.open > 0) {
    //     this.filter.ticket = 'open';
    // } else if (this.filter.closed > 0) {
    //     this.filter.ticket = 'closed';
    // } else {
    //     this.ticket_type = '';
    // }
    
    // this.filter.ticket = this.filter.open > 0 ? this.ticket_type == "open" : this.ticket_type == "closed";
  },
  methods: {
    onDate() {
			this.table.api().ajax.reload();
		},
    statusType(event) {
      sessionStorage.clear();
      let self = this;
      self.filter = {};
      self.status_approval = event.target.value;
      self.filter.status_approval = self.status_approval;
      self.filter.dt1 = self.dateStart;
      self.filter.dt2 = self.dateEnd;
      self.onDate();
    //   self.filter.datatype = self.data_type;
      // console.log(self.filter.ticket);
    //   self.loadAllStatus(self.filter.ticket);
			// self.table.api().ajax.reload();
    },
    handleClick(e) {
      if (e.target.matches(".link-order")) {
          let route = this.$router.resolve({
                path: "/shipper/verification/" + e.target.dataset.id
            });
            window.open(route.href,"_blank");
        // return false;
      }
      if(e.target.closest('.btn-comment')) {
        //this.isShowTicket = !this.isShowTicket;
        var el = e.target.closest('.btn-comment');
        // console.log(el)
        this.$root.$emit("openTicketComment", el.dataset.id);
        return false;
      }
      if (e.target.matches("button")) {
        if (e.target.dataset.action == "change") {
          this.form.status = e.target.dataset.status;
          Swal.fire({
            title:
              e.target.dataset.status == 3
                ? "Pending?"
                : e.target.dataset.status == 2
                ? "Closed?"
                : "Open?",
            showCancelButton: true,
            confirmButtonText: `Ya`,
            cancelButtonText: "Tidak",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              var data = Object.keys(this.form)
                .map(
                  (key) =>
                    encodeURIComponent(key) +
                    "=" +
                    encodeURIComponent(this.form[key])
                )
                .join("&");

              authFetch("/ticket/history/" + e.target.dataset.id, {
                method: "PUT",
                body: data,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  if (js.success) {
                    Swal.fire("Proses Berhasil", ``, "success");
                    this.table.api().ajax.reload();
                    this.loadAllStatus();
                  } else {
                    Swal.fire("Proses gagal", ``, "error");
                    this.table.api().ajax.reload();
                  }
                });
            }
          });
        }
        // console.log(e.target);
        return false;
      }
    },
  },
  mounted() {
    const e = this.$refs;
    const self = this;
    new Daterangepicker(
      this.$refs.daterange,
      {
        startDate: !this.dt1 ? moment().day(-31) : this.dt1,
        endDate: !this.dt2 ? moment() : this.dt2,
      },
      function (d1, d2) {
        self.dateStart = d1.format("YYYY-MM-DD");
        self.dateEnd = d2.format("YYYY-MM-DD");
        self.filter.status_approval = self.status_approval;
        self.filter.dt1 = self.dateStart;
        self.filter.dt2 = self.dateEnd;
        self.onDate();
        // self.data_type = self.data_type;
        // self.filter.datatype = self.data_type;
        // var json = { dt1: self.filter.dt1, dt2: self.filter.dt2, ticket: self.filter.ticket };
        // self.loadAllStatus(json);
      }
    );

    this.table = createTable(e.tblverif, {
      title: "",
      roles: this.$route.params.roles,
      ajax: "/shipper/verification",
      frame: false,
      scrollX: true,
      autoWidth:false,
      deferRender : true,
      processing: true,
      language: {
        processing: '<i class="fa fa-spinner fa-spin" style="font-size:50px;color:black;"></i>'},
      selectedRowClass: "",
      toolbarEl: e.toolbar,
      lengthChange: true,
      filter: true,
      filterBy: [0, 1, 2, 3, 4],
      stateSave: true,
      paramData: function (d) {
        var filter = JSON.parse(JSON.stringify(self.filter));
        for (const key in filter) {
            d[key] = filter[key] === false ? 0: filter[key];
        }
      },
      order: [[ 4, 'desc' ]],
      columns: [
        { data: "shipper_code" },
        { data: "nik" },
        { data: "nama" },
        { data: "nomor" },
        { data: "email" },
        {
          data: "ktp_verify_created",
          render: function (data, type, row, meta) {
              return moment(data).format("DD/MM/YYYY HH:mm:ss", true);
          },
        },
        {
          data: "ktp_verify",
          render: function (data, type, row, meta) {
            if (data == 0) return "-";
            else if (data == 1)
              return '<span class="badge badge-success">Approved</span>';
            else if (data == 2)
              return '<span class="badge badge-warning">Requested</span>';
            else if (data == 3)
              return '<span class="badge badge-danger">Rejected</span>';
            else return "-";
          },
        },
        {
          data: "id",
          sortable:false,
          render: function (data, type, row, meta) {
            return `<span class="link link-order text-info" style="cursor:pointer;" data-id="${data}">Lihat Detail</span>`;
          },
        },
      ],
      rowCallback: function (row, data) {},
      drawCallback: function () {},
      initComplete: function () {
        $('.loading-overlay').removeClass('show');
      },
    });
  },
};
</script>
<style>
.table-ticket .dataTables_scrollBody{min-height: 100px}
</style>